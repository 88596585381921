import axios from "axios";
import React, { useEffect, useState } from "react";
import HOC from "../../layout/HOC";
import Baseurl from "../../../Baseurl";
import { CgProfile } from "react-icons/cg";
import auth from "../../../Auth";
import { IoMdClose } from "react-icons/io";
import { GrNotification } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { FaTrash, FaRegEye } from "react-icons/fa";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";

const Entrepreneurship = () => {
  const [categories, setCategories] = useState([]);
  const [popup, setPopup] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [categoryName, setCategoryName] = useState("");
  const [backgroundImage, setBackgroundImage] = useState();
  const [description, setDescription] = useState("");

  const categoryDelete = async (id) =>{

    if (!window.confirm("Are you sure")) {
      return;
    }

    const url = Baseurl() + `entrepreneurship/deleteCategory/${id}`;

    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("studyhox")}`,
      },
    }; 

    try {
      const res = await axios.delete(url, headers);
      getCategories();
      console.log("get sem by id data ", res.data);
    } catch (err) {
      console.log("err", err);
    }
  }


    const addCategory = (e) => {
      e.preventDefault();
      const url = Baseurl() + "entrepreneurship/addCategory";
  
      const fd = new FormData();
      fd.append("title", categoryName);
      fd.append("subtitle", description);
      fd.append("image", backgroundImage);
  
      axios
        .post(url, fd, auth)
        .then((res) => {
          console.log(res);
          toast("Category Added Sucessfully");
          getCategories();
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
        setPopup(false);
    };

  const getBlogsbyId = async (id) => {
    const url = Baseurl() + `entrepreneurship/getBlogsByCategory/${id}`;
    try {
      const res = await axios.get(url, auth);
      setCategories(res.data.data);
      console.log("get sem by id data", res.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const getCategories = async () => {
    const url = Baseurl() + "entrepreneurship/getAllCategory";
    try {
      axios
        .get(url, auth)
        .then((res) => {
          setCategories(res.data.allCategories);
          setLoading(false);
        })
        .catch((res) => {
          setLoading(false);
        });
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <div className="pb-4 sticky w-full flex justify-between items-center  top-0 bg-slate-900">
        <span className="tracking-widest text-blue-400 font-semibold uppercase text-lg">
          Entrepreneurship Categories
        </span>
        <button
          onClick={() => {
            setPopup(!popup);
          }}
          className="md:py-2 px-3 md:px-4 py-1 rounded-sm bg-blue-400 text-white tracking-wider"
        >
          Add Category
        </button>
      </div>

      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          popup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen "
            : "hidden"
        }
      >
        <div
          style={{ maxHeight: "90vh" }}
          className="bg-white p-3 px-5 lg:w-3/6  md:w-4/6 w-5/6 mx-auto  rounded-lg"
        >
          <div className="flex justify-between">
            <span className=" font-semibold text-indigo-500 ">
              Add Entrepreneurship Category
            </span>
            <div className="text-indigo-600 py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
              <IoMdClose
                onClick={() => {
                  setPopup(false);
                }}
              />{" "}
            </div>
          </div>
          {/* form */}

          <form
            className="grid  grid-cols-1 gap-x-7 gap-y-4 py-4"
            onSubmit={(e) => {addCategory(e);}}
          >
            {/* Question */}
            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Category Name
              </label>
              <input
                id="name"
                required
                value={categoryName}
                onChange={(e) => {setCategoryName(e.target.value);}}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Description
              </label>
              <input
                id="name"
                required
                value={description}
                onChange={(e) => {setDescription(e.target.value);}}
                type="text"
                name="description"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Background Image
              </label>
              <input
                accept="image/*"
                id="name"
                onChange={(e) => setBackgroundImage(e.target.files[0])}
                required
                type="file"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <button
              type="submit"
              className="bg-indigo-500 cursor-pointer w-40 hover:bg-indigo-600 py-1 rounded-full"
            >
              Add
            </button>
          </form>
        </div>
      </section>

      {/* Semesters Data */}
      <div className=" wcomp overflow-y-auto">
        <table className="table-auto  w-full text-left whitespace-no-wrap">
          <thead>
            <tr className="bg-blue-400 border-b border-gray-700 shadow-xl text-white">
            <th className="px-4 py-3 title-font tracking-widest font-medium md:text-base text-sm  ">
                Image
              </th>
              <th className="px-4 py-3 title-font tracking-widest font-medium md:text-base text-sm  ">
                Category Name
              </th>
              <th className="px-4 py-3 title-font tracking-widest font-medium md:text-base text-sm  ">
                Subtitle
              </th>
              <th className="px-4 py-3 title-font tracking-widest rounded-tr-lg font-medium md:text-base text-sm  ">
                Blogs
              </th>
              <th className="px-4 py-3 title-font tracking-widest rounded-tr-lg font-medium md:text-base text-sm  ">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <Oval color="white" />
            ) : (
              categories?.map((data) => {
                return (
                  <tr className="tracking-wider bg-[rgb(30,39,66)] ">

                    <td className="px-4 py-2 w-36 md:w-40">
                      <img
                        src={data.image}
                        alt=""
                        className="w-14 md:w-14 h-14 md:h-14 object-cover "
                      />
                    </td>

                    <td className="px-4 py-3">{data.title}</td>
                    <td className="px-4 py-3">{data.subtitle}</td>
                    <td className="px-4 py-3 flex items-center space-x-3 md:space-x-5">
                      <FaRegEye
                        onClick={() => {
                          navigate(`/blogs/${data._id}`);
                        }}
                        className="text-green-500 sm:text-lg md:text-xl cursor-pointer"
                      />
                      <button
                        className="text-green-500 sm:text-lg md:text-xl cursor-pointer"
                        onClick={() => {
                          navigate(`/blogs/${data.title}/${data._id}`);
                        }}
                      >
                        View Blogs
                      </button>
                    </td>
                    <td>
                      <FaTrash onClick={() => categoryDelete(data._id)} className="text-red-600 text-lg md:text-xl cursor-pointer"/>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default HOC(Entrepreneurship);
