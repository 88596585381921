import React from "react";

const Baseurl = () => {
  // return "https://bricksagent.ziltinvestment.com/api/";
  // return "https://portal.ronakprajapati.tech/api/";
  return "https://findmyfuture.in/api/";
  // return "http://localhost:8000/api/";
};

export default Baseurl;
