import axios from "axios";
import React, { useEffect, useState } from "react";
import HOC from "../../layout/HOC";
import Baseurl from "../../../Baseurl";
import { CgProfile } from "react-icons/cg";
import auth from "../../../Auth";
import { IoMdClose } from "react-icons/io";
import { GrNotification } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { FaTrash, FaRegEye } from "react-icons/fa";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";

const AccountDeletionRequest = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  const getStudyAbroadRequests = async () => {
    const url = Baseurl() + "student/get-account-deletion-requests";
    try {
      axios
        .get(url, auth)
        .then((res) => {
          setRequests(res.data.allcourses);
          setLoading(false);
        })
        .catch((res) => {
          setLoading(false);
        });
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getStudyAbroadRequests();
  }, []);

  return (
    <>
      <div className="pb-4 sticky w-full flex justify-between items-center  top-0 bg-slate-900">
        <span className="tracking-widest text-blue-400 font-semibold uppercase text-lg">
          Account Deletion Requests
        </span>
      </div>

      <div className=" wcomp overflow-y-auto">
        <table className="table-auto  w-full text-left whitespace-no-wrap">
          <thead>
            <tr className="bg-blue-400 border-b border-gray-700 shadow-xl text-white">
            <th className="px-4 py-3 title-font tracking-widest font-medium md:text-base text-sm  ">
                Name
              </th>
              <th className="px-4 py-3 title-font tracking-widest font-medium md:text-base text-sm  ">
                Mobile
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <Oval color="white" />
            ) : (
              requests.reverse()?.map((data) => {
                return (
                  <tr className="tracking-wider bg-[rgb(30,39,66)] ">
                    <td className="px-4 py-3">{data.name}</td>
                    <td className="px-4 py-3">{data.mobile}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default HOC(AccountDeletionRequest);
