import axios from "axios";
import React, { useEffect, useState } from "react";
import HOC from "../../layout/HOC";
import Baseurl from "../../../Baseurl";
import auth from "../../../Auth";
import { IoMdClose } from "react-icons/io";
import { FaTrash, FaEdit } from "react-icons/fa";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";

const StudyAbroadData = () => {
  const [abroadData, setAbroadData] = useState([]);
  const [popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [addCoursePopup, setAddCoursePopup] = useState(false);
  const [courseTitle, setCourseTitle] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [fees, setFees] = useState("");
  const [other, setOther] = useState("");
  const [courses, setCourses] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [universityInfo, setUniversityInfo] = useState([]);
  const [syllabus, setSyllabus] = useState([]);
  const [isBOpen, setIsBOpen] = useState(false);
  const [isUOpen, setIsUOpen] = useState(false);
  const [isSOpen, setIsSOpen] = useState(false);
  const [b, setB] = useState("");
  const [u, setU] = useState("");
  const [s, setS] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [editDataId, setEditDataId] = useState(null);

  const addCourse = () => {
    if (courseTitle.length != 0 && courseDescription.length != 0) {
      let link = {
        title: courseTitle,
        description: courseDescription,
      };
      setCourseTitle("");
      setCourseDescription("");
      setAddCoursePopup(false);
      setCourses((relatedLinks) => [...relatedLinks, link]);
    }
  };

  const addBenefit = () => {
    if (b.length != 0) {
      setBenefits([...benefits, b]);
      setB("");
      setIsBOpen(false);
    }
  };

  const addUniversityInfo = () => {
    if (u.length != 0) {
      setUniversityInfo([...universityInfo, u]);
      setU("");
      setIsUOpen(false);
    }
  };

  const addSyllabus = () => {
    if (s.length != 0) {
      setSyllabus([...syllabus, s]);
      setS("");
      setIsSOpen(false);
    }
  };

  const abroadDataDelete = async (id) => {
    if (!window.confirm("Are you sure")) {
      return;
    }

    const url = Baseurl() + `skills/deleteskill/${id}`;
    try {
      const res = await axios.delete(url, auth);
      getAbroadData();
      console.log("get sem by id  ", res.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const getAbroadData = async () => {
    const url = Baseurl() + "skills/getallskills";
    try {
      axios
        .get(url, auth)
        .then((res) => {
          setAbroadData(res.data.allskills);
          setLoading(false);
        })
        .catch((res) => {
          setLoading(false);
        });
    } catch (err) {
      console.log("err", err);
    }
  };

  const addAbroadData = async (e, d) => {
    e.preventDefault();
    const url = Baseurl() + "skills/addskill";

    const data = {
      skillname: JSON.stringify(d),
    };

    try {
      const res = await axios.post(url, data, auth);
      alert("Added Successfully");
      window.location.reload();
    } catch (err) {
      console.log("err", err);
      alert("Please try again");
    }
  };

  const updateAbroadData = async (e, d) => {
    e.preventDefault();
    const url = Baseurl() + `skills/updateskill/${editDataId}`;

    const data = {
      skillname: JSON.stringify(d),
    };

    try {
      const res = await axios.put(url, data, auth);
      alert("Added Successfully");
      window.location.reload();
    } catch (err) {
      console.log("err", err);
      alert("Please try again");
    }
  };

  useEffect(() => {
    getAbroadData();
  }, []);

  const editData = (data) => {
    setTitle(data.title);
    setDescription(data.description);
    setFees(data.fees);
    setOther(data.other);
    setCourses(data.courses);
    setBenefits(data.benefits);
    setUniversityInfo(data.universityInfo);
    setSyllabus(data.syllabus);
    setIsEdit(true);
    setPopup(true);
  };

  return (
    <>
      <div className="pb-4 sticky w-full flex justify-between items-center  top-0 bg-slate-900">
        <span className="tracking-widest text-blue-400 font-semibold uppercase text-lg">
          Study Abroad Data
        </span>
        <button
          onClick={() => {
            setPopup(!popup);
          }}
          className="md:py-2 px-3 md:px-4 py-1 rounded-sm bg-blue-400 text-white tracking-wider"
        >
          Add Data
        </button>
      </div>

      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          popup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen"
            : "hidden"
        }
      >
        <div
          style={{ maxHeight: "90vh", overflow: "auto" }}
          className="bg-white p-3 px-5 lg:w-3/6  md:w-4/6 w-5/6 mx-auto  rounded-lg"
        >
          <div className="flex justify-between">
            <span className=" font-semibold text-indigo-500 ">{isEdit === true ? "Edit Data" : "Add Data"}</span>
            <div className="text-indigo-600 py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
              <IoMdClose
                onClick={() => {
                  window.location.reload();
                }}
              />{" "}
            </div>
          </div>
          {/* form */}

          <form className="grid  grid-cols-1 gap-x-7 gap-y-4 py-4">
            {/* Question */}
            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Title
              </label>
              <input
                id="name"
                required
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Description
              </label>
              <input
                id="name"
                required
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                type="text"
                name="description"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Fees
              </label>
              <textarea
                onChange={(e) => {
                  setFees(e.target.value);
                }}
                value={fees}
                required
                name="content"
                id="name"
                cols="30"
                rows="4"
                className="bg-gray-100 text-gray-800 tracking-wider text-sm py-1 px-2 outline-indigo-400"
              ></textarea>
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Other Data
              </label>
              <textarea
                onChange={(e) => {
                  setOther(e.target.value);
                }}
                value={other}
                required
                name="content"
                id="name"
                cols="30"
                rows="4"
                className="bg-gray-100 text-gray-800 tracking-wider text-sm py-1 px-2 outline-indigo-400"
              ></textarea>
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Courses &nbsp; &nbsp;
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setAddCoursePopup(true);
                  }}
                  type="button"
                  className="bg-indigo-500 cursor-pointer w-7 hover:bg-indigo-600 py-1 rounded-full text-white"
                >
                  +
                </button>
              </label>
              //* Show Added related links
              {courses.map((course, index) => {
                return (
                  <div className="flex row">
                    <div className="text-red-800 mb-1.5 tracking-wider font-semibold text-sm">
                      {course.title} <br /> {course.description}
                      &nbsp; &nbsp;
                    </div>
                    <button
                      onClick={() => {
                        let allSetlectedCourses = [...courses];
                        allSetlectedCourses.splice(index, 1);
                        setCourses(allSetlectedCourses);
                      }}
                      type="button"
                      className="bg-red-500 cursor-pointer w-8 h-8 hover:bg-red-600 py-1 rounded-full text-white"
                    >
                      -
                    </button>
                  </div>
                );
              })}
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Benefits &nbsp; &nbsp;
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setIsBOpen(true);
                  }}
                  type="button"
                  className="bg-indigo-500 cursor-pointer w-7 hover:bg-indigo-600 py-1 rounded-full text-white"
                >
                  +
                </button>
              </label>
              //* Show Added related links
              {benefits.map((benefit, index) => {
                return (
                  <div className="flex row">
                    <div className="text-red-800 mb-1.5 tracking-wider font-semibold text-sm">
                      {benefit}
                      &nbsp; &nbsp;
                    </div>
                    <button
                      onClick={() => {
                        let allSetlectedBenefits = [...benefits];
                        allSetlectedBenefits.splice(index, 1);
                        setBenefits(allSetlectedBenefits);
                      }}
                      type="button"
                      className="bg-red-500 cursor-pointer w-8 h-8 hover:bg-red-600 py-1 rounded-full text-white"
                    >
                      -
                    </button>
                  </div>
                );
              })}
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                University Info &nbsp; &nbsp;
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setIsUOpen(true);
                  }}
                  type="button"
                  className="bg-indigo-500 cursor-pointer w-7 hover:bg-indigo-600 py-1 rounded-full text-white"
                >
                  +
                </button>
              </label>
              //* Show Added related links
              {universityInfo.map((info, index) => {
                return (
                  <div className="flex row">
                    <div className="text-red-800 mb-1.5 tracking-wider font-semibold text-sm">
                      {info}
                      &nbsp; &nbsp;
                    </div>
                    <button
                      onClick={() => {
                        let allSetlectedInfo = [...universityInfo];
                        allSetlectedInfo.splice(index, 1);
                        setUniversityInfo(allSetlectedInfo);
                      }}
                      type="button"
                      className="bg-red-500 cursor-pointer w-8 h-8 hover:bg-red-600 py-1 rounded-full text-white"
                    >
                      -
                    </button>
                  </div>
                );
              })}
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Syllabus &nbsp; &nbsp;
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setIsSOpen(true);
                  }}
                  type="button"
                  className="bg-indigo-500 cursor-pointer w-7 hover:bg-indigo-600 py-1 rounded-full text-white"
                >
                  +
                </button>
              </label>
              //* Show Added related links
              {syllabus.map((syllab, index) => {
                return (
                  <div className="flex row">
                    <div className="text-red-800 mb-1.5 tracking-wider font-semibold text-sm">
                      {syllab}
                      &nbsp; &nbsp;
                    </div>
                    <button
                      onClick={() => {
                        let allSetlectedSyllabus = [...syllabus];
                        allSetlectedSyllabus.splice(index, 1);
                        setSyllabus(allSetlectedSyllabus);
                      }}
                      type="button"
                      className="bg-red-500 cursor-pointer w-8 h-8 hover:bg-red-600 py-1 rounded-full text-white"
                    >
                      -
                    </button>
                  </div>
                );
              })}
            </div>

            <button
              type="button"
              className="bg-indigo-500 cursor-pointer w-40 hover:bg-indigo-600 py-1 rounded-full"
              onClick={(e) => {
                e.preventDefault();
                let object = {
                  title: title,
                  description: description,
                  fees: fees,
                  other: other,
                  courses: courses,
                  syllabus: syllabus,
                  universityInfo: universityInfo,
                  benefits: benefits,
                };
                console.log(object);
                isEdit === true ? updateAbroadData(e, object) : addAbroadData(e, object);
              }}
            >
              {isEdit === true ? "Edit Data" : "Add Data"}
            </button>
          </form>
        </div>
      </section>

      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          addCoursePopup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen "
            : "hidden"
        }
      >
        <div
          style={{ maxHeight: "90vh" }}
          className="bg-white p-3 px-5 lg:w-3/6  md:w-4/6 w-5/6 mx-auto  rounded-lg"
        >
          <div className="flex justify-between">
            <span className=" font-semibold text-indigo-500 ">Add Course</span>
            <div className="text-indigo-600 py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
              <IoMdClose
                onClick={() => {
                  setAddCoursePopup(!addCoursePopup);
                }}
              />{" "}
            </div>
          </div>
          {/* form */}

          <form
            className="grid  grid-cols-1 gap-x-7 gap-y-4 py-4"
            onSubmit={() => {}}
          >
            {/* Question */}
            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Title
              </label>
              <input
                id="name"
                required
                value={courseTitle}
                onChange={(e) => {
                  setCourseTitle(e.target.value);
                }}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Description
              </label>
              <input
                id="name"
                required
                value={courseDescription}
                onChange={(e) => {
                  setCourseDescription(e.target.value);
                }}
                type="text"
                name="description"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <button
              onClick={() => {
                addCourse();
              }}
              type="button"
              className="bg-indigo-500 cursor-pointer w-40 hover:bg-indigo-600 py-1 rounded-full"
            >
              Add
            </button>
          </form>
        </div>
      </section>

      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          isBOpen
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen "
            : "hidden"
        }
      >
        <div
          style={{ maxHeight: "90vh" }}
          className="bg-white p-3 px-5 lg:w-3/6  md:w-4/6 w-5/6 mx-auto  rounded-lg"
        >
          <div className="flex justify-between">
            <span className=" font-semibold text-indigo-500 ">Add Point</span>
            <div className="text-indigo-600 py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
              <IoMdClose
                onClick={() => {
                  setIsBOpen(!isBOpen);
                }}
              />{" "}
            </div>
          </div>
          {/* form */}

          <form
            className="grid  grid-cols-1 gap-x-7 gap-y-4 py-4"
            onSubmit={() => {}}
          >
            {/* Question */}
            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Point
              </label>
              <input
                id="name"
                required
                value={b}
                onChange={(e) => {
                  setB(e.target.value);
                }}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <button
              onClick={() => {
                addBenefit();
              }}
              type="button"
              className="bg-indigo-500 cursor-pointer w-40 hover:bg-indigo-600 py-1 rounded-full"
            >
              Add
            </button>
          </form>
        </div>
      </section>

      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          isUOpen
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen "
            : "hidden"
        }
      >
        <div
          style={{ maxHeight: "90vh" }}
          className="bg-white p-3 px-5 lg:w-3/6  md:w-4/6 w-5/6 mx-auto  rounded-lg"
        >
          <div className="flex justify-between">
            <span className=" font-semibold text-indigo-500 ">Add Point</span>
            <div className="text-indigo-600 py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
              <IoMdClose
                onClick={() => {
                  setIsUOpen(!isUOpen);
                }}
              />{" "}
            </div>
          </div>
          {/* form */}

          <form
            className="grid  grid-cols-1 gap-x-7 gap-y-4 py-4"
            onSubmit={() => {}}
          >
            {/* Question */}
            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Point
              </label>
              <input
                id="name"
                required
                value={u}
                onChange={(e) => {
                  setU(e.target.value);
                }}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <button
              onClick={() => {
                addUniversityInfo();
              }}
              type="button"
              className="bg-indigo-500 cursor-pointer w-40 hover:bg-indigo-600 py-1 rounded-full"
            >
              Add
            </button>
          </form>
        </div>
      </section>

      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          isSOpen
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen "
            : "hidden"
        }
      >
        <div
          style={{ maxHeight: "90vh" }}
          className="bg-white p-3 px-5 lg:w-3/6  md:w-4/6 w-5/6 mx-auto  rounded-lg"
        >
          <div className="flex justify-between">
            <span className=" font-semibold text-indigo-500 ">Add Point</span>
            <div className="text-indigo-600 py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
              <IoMdClose
                onClick={() => {
                  setIsSOpen(!isSOpen);
                }}
              />{" "}
            </div>
          </div>
          {/* form */}

          <form
            className="grid  grid-cols-1 gap-x-7 gap-y-4 py-4"
            onSubmit={() => {}}
          >
            {/* Question */}
            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Point
              </label>
              <input
                id="name"
                required
                value={s}
                onChange={(e) => {
                  setS(e.target.value);
                }}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <button
              onClick={() => {
                addSyllabus();
              }}
              type="button"
              className="bg-indigo-500 cursor-pointer w-40 hover:bg-indigo-600 py-1 rounded-full"
            >
              Add
            </button>
          </form>
        </div>
      </section>

      {/* Semesters Data */}
      <div className=" wcomp overflow-y-auto">
        <table className="table-auto  w-full text-left whitespace-no-wrap">
          <thead>
            <tr className="bg-blue-400 border-b border-gray-700 shadow-xl text-white">
              <th className="px-4 py-3 title-font tracking-widest font-medium md:text-base text-sm  ">
                Title
              </th>
              <th className="px-4 py-3 title-font tracking-widest font-medium md:text-base text-sm  ">
                Description
              </th>
              <th className="px-4 py-3 title-font tracking-widest font-medium md:text-base text-sm  ">
                Edit
              </th>
              <th className="px-4 py-3 title-font tracking-widest font-medium md:text-base text-sm  ">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <Oval color="white" />
            ) : (
              abroadData?.map((data) => {
                return (
                  <tr className="tracking-wider bg-[rgb(30,39,66)] ">
                    <td className="px-4 py-3">
                      <a target="_blank" href={data.pdfLink}>
                        {JSON.parse(data.skillname).title}
                      </a>
                    </td>

                    <td className="px-4 py-3">
                      {JSON.parse(data.skillname).description}
                    </td>
                    <td>
                      <FaEdit
                        onClick={() => { 
                          setEditDataId(data._id);
                          editData(JSON.parse(data.skillname));
                        }}
                        className="text-red-600 text-lg md:text-xl cursor-pointer"
                      />
                    </td>
                    <td>
                      <FaTrash
                        onClick={() => abroadDataDelete(data._id)}
                        className="text-red-600 text-lg md:text-xl cursor-pointer"
                      />
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default HOC(StudyAbroadData);
