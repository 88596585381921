import axios from "axios";
import React, { useEffect, useState } from "react";
import HOC from "../../layout/HOC";
import Baseurl from "../../../Baseurl";
import { CgProfile } from "react-icons/cg";
import auth from "../../../Auth";
import { IoMdClose } from "react-icons/io";
import { GrNotification } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { FaTrash, FaRegEye } from "react-icons/fa";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";

const Roadmaps = () => {
  const [roadmaps, setRoadmaps] = useState([]);
  const [popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [backgroundImage, setBackgroundImage] = useState();
  const [description, setDescription] = useState("");
  const [addLinkPopup, setAddLinkPopup] = useState(false);
  const [linkTitle, setLinkTitle] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [pdfLink, setPdfLink] = useState("");
  const [relatedLinks, setRelatedLinks] = useState([]);


  const addRelatedLink = () => {
    if(linkTitle.length != 0 && linkUrl.length != 0){
      let link = {
        title : linkTitle,
        url : linkUrl
      };
      setLinkTitle("") ;
      setLinkUrl("");
      setAddLinkPopup(false);
      setRelatedLinks (relatedLinks => [...relatedLinks, link]);  
    }
  }

  const removeRelatedLink = (index) => {
    let links = relatedLinks;
    links.splice(index, 1);
    setRelatedLinks(links);
  }

  const roadmapDelete = async (id) =>{


    if (!window.confirm("Are you sure")) {
      return;
    }

    const url = Baseurl() + `roadmap/deleteRoadMap/${id}`;
    try {
      const res = await axios.delete(url, auth);
      getRoadmaps();
      console.log("get sem by id  ", res.data);
    } catch (err) {
      console.log("err", err);
    }
  }

  const getRoadmaps = async () => {
    const url = Baseurl() + "roadmap/getAllRoadMaps";
    try {
      axios
        .get(url, auth)
        .then((res) => {
          setRoadmaps(res.data.allRoadMaps);
          setLoading(false);
        })
        .catch((res) => {
          setLoading(false);
        });
    } catch (err) {
      console.log("err", err);
    }
  };

  const addRoadmap = (e) => {
    e.preventDefault();
    const url = Baseurl() + "roadmap/addRoadMap";

    const fd = new FormData();
    fd.append("title", title);
    fd.append("description", description);
    fd.append("image", backgroundImage);
    fd.append("pdfLink", pdfLink);
    fd.append("relatedLinks", JSON.stringify(relatedLinks));

    axios
      .post(url, fd, auth)
      .then((res) => {
        console.log(res);
        toast("Roadmap Added Sucessfully");
        getRoadmaps();
        setBackgroundImage(null);
        setTitle("");
        setDescription("");
        setRelatedLinks([]);
        setPdfLink("");
      })
      .catch((err) => {
        console.log(err);
      });
      setPopup(false);
  };

  useEffect(() => {
    getRoadmaps();
  }, []);

  return (
    <>
      <div className="pb-4 sticky w-full flex justify-between items-center  top-0 bg-slate-900">
        <span className="tracking-widest text-blue-400 font-semibold uppercase text-lg">
          Roadmaps
        </span>
        <button
          onClick={() => {
            setPopup(!popup);
          }}
          className="md:py-2 px-3 md:px-4 py-1 rounded-sm bg-blue-400 text-white tracking-wider"
        >
          Add Roadmap
        </button>
      </div>

      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          popup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen "
            : "hidden"
        }
      >
        <div
          style={{ maxHeight: "90vh" , overflow:"auto" }}
          className="bg-white p-3 px-5 lg:w-3/6  md:w-4/6 w-5/6 mx-auto  rounded-lg"
        >
          <div className="flex justify-between">
            <span className=" font-semibold text-indigo-500 ">
              Add Roadmap
            </span>
            <div className="text-indigo-600 py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
              <IoMdClose
                onClick={() => {
                  setPopup(false);
                }}
              />{" "}
            </div>
          </div>
          {/* form */}

          <form
            className="grid  grid-cols-1 gap-x-7 gap-y-4 py-4"
            onSubmit={(e) => {
              addRoadmap(e);
            }}
          >
            {/* Question */}
            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Title
              </label>
              <input
                id="name"
                required
                value={title}
                onChange={(e) => {setTitle(e.target.value);}}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Description
              </label>
              <input
                id="name"
                required
                value={description}
                onChange={(e) => {setDescription(e.target.value);}}
                type="text"
                name="description"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                PDF Link
              </label>
              <input
                id="name"
                required
                value={pdfLink}
                onChange={(e) => {setPdfLink(e.target.value);}}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Background Image
              </label>
              <input
                accept="image/*"
                id="name"
                onChange={(e) => setBackgroundImage(e.target.files[0])}
                required
                type="file"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Related Links &nbsp; &nbsp;
                <button
                onClick={() => {setAddLinkPopup(true);}}
                type="button"
                className="bg-indigo-500 cursor-pointer w-7 hover:bg-indigo-600 py-1 rounded-full text-white"
                >
                  +
                </button>
              </label>
               //* Show Added related links
              {relatedLinks.map((link, index) => {
                return (
                  <div className="flex row">
                  <a className="text-red-800 mb-1.5 tracking-wider font-semibold text-l w-fit" href={link.url} target="_blank">{link.title}
                  &nbsp; &nbsp;
                  </a>
                  <button
                  onClick={() => {removeRelatedLink(index);}}
                  type="button"
                  className="bg-red-500 cursor-pointer w-8 h-8 hover:bg-red-600 py-1 rounded-full text-white"
                  >
                  -
                </button>
                </div>
                );
              })}
            </div>

            <button
              type="submit"
              className="bg-indigo-500 cursor-pointer w-40 hover:bg-indigo-600 py-1 rounded-full"
            >
              Add
            </button>
          </form>
        </div>
      </section>

      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          addLinkPopup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen "
            : "hidden"
        }
      >
        <div
          style={{ maxHeight: "90vh" }}
          className="bg-white p-3 px-5 lg:w-3/6  md:w-4/6 w-5/6 mx-auto  rounded-lg"
        >
          <div className="flex justify-between">
            <span className=" font-semibold text-indigo-500 ">
              Add Related Link
            </span>
            <div className="text-indigo-600 py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
              <IoMdClose
                onClick={() => {
                  setAddLinkPopup(!addLinkPopup);
                }}
              />{" "}
            </div>
          </div>
          {/* form */}

          <form
            className="grid  grid-cols-1 gap-x-7 gap-y-4 py-4"
            onSubmit={() => {}}
          >
            {/* Question */}
            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Title
              </label>
              <input
                id="name"
                required
                value={linkTitle}
                onChange={(e) => {setLinkTitle(e.target.value);}}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                URL
              </label>
              <input
                id="name"
                required
                value={linkUrl}
                onChange={(e) => {setLinkUrl(e.target.value);}}
                type="text"
                name="description"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <button
              onClick={() => {addRelatedLink();}}
              type="button"
              className="bg-indigo-500 cursor-pointer w-40 hover:bg-indigo-600 py-1 rounded-full"
            >
              Add
            </button>
          </form>
        </div>
      </section>

      {/* Semesters Data */}
      <div className=" wcomp overflow-y-auto">
        <table className="table-auto  w-full text-left whitespace-no-wrap">
          <thead>
            <tr className="bg-blue-400 border-b border-gray-700 shadow-xl text-white">
            <th className="px-4 py-3 title-font tracking-widest font-medium md:text-base text-sm  ">
                Image
              </th>
              <th className="px-4 py-3 title-font tracking-widest font-medium md:text-base text-sm  ">
                Title
              </th>
              <th className="px-4 py-3 title-font tracking-widest font-medium md:text-base text-sm  ">
                Description
              </th>
              <th className="px-4 py-3 title-font tracking-widest rounded-tr-lg font-medium md:text-base text-sm  ">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <Oval color="white" />
            ) : (
              roadmaps?.map((data) => {
                return (
                  <tr className="tracking-wider bg-[rgb(30,39,66)] ">

                    <td className="px-4 py-2 w-36 md:w-40">
                      <img
                        src={data.image}
                        alt=""
                        className="w-14 md:w-14 h-14 md:h-14 object-cover "
                      />
                    </td>

                    <td className="px-4 py-3"><a target="_blank" href={data.pdfLink}>{data.title}</a></td>

                    <td className="px-4 py-3">{data.description}</td>
                    <td>
                      <FaTrash onClick={() => roadmapDelete(data._id)} className="text-red-600 text-lg md:text-xl cursor-pointer"/>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default HOC(Roadmaps);
