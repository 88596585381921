import axios from "axios";
import React, { useEffect, useState } from "react";
import HOC from "../../layout/HOC";
import Baseurl from "../../../Baseurl";
import auth from "../../../Auth";
import { IoMdClose } from "react-icons/io";
import { FaTrash } from "react-icons/fa";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";

const Hackathon = () => {
  const [hackathon, setHackathon] = useState([]);
  const [popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(true);

  const [name, setName] = useState("");
  const [organizer, setOrganizer] = useState("");
  const [location, setLocation] = useState("");
  const [tags, setTags] = useState("");
  const [description, setDescription] = useState("");
  const [guidelines, setGuildelines] = useState("");
  const [rules, setRules] = useState("");
  const [registrationDeadline, setRegistrationDeadline] = useState("");
  const [prizeAmount, setPrizeAmount] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [url, setUrl] = useState("");
  const [image, setImage] = useState();

  const hackathonDelete = async (id) => {
    if (!window.confirm("Are you sure")) {
      return;
    }

    const url = Baseurl() + `cwhj/delete-hackathon/${id}`;
    try {
      const res = await axios.delete(url, auth);
      getHackathon();
    } catch (err) {
      console.log("err", err);
    }
  };

  const getHackathon = async () => {
    const url = Baseurl() + "cwhj/get-all-hackathon";
    try {
      axios
        .get(url, auth)
        .then((res) => {
          setHackathon(res.data.allHackathons);
          setLoading(false);
        })
        .catch((res) => {
          setLoading(false);
        });
    } catch (err) {
      console.log("err", err);
    }
  };

  const addHackathon = (e) => {
    e.preventDefault();
    const ur = Baseurl() + "cwhj/add-hackathon";

    const fd = new FormData();

    let t = tags.split(";");
    let g = guidelines.split(";");
    let r = rules.split(";");

    fd.append("name", name);
    fd.append("organizer", organizer);
    fd.append("location", location);
    t.forEach((v, i) => {
      fd.append(`tags[${i}]`, v);
    });
    fd.append("description", description);
    g.forEach((v, i) => {
      fd.append(`guidelines[${i}]`, v);
    });
    r.forEach((v, i) => {
      fd.append(`rules[${i}]`, v);
    });
    fd.append("registrationDeadline", registrationDeadline);
    fd.append("prizeAmount", prizeAmount);
    fd.append("contactName", contactName);
    fd.append("contactNumber", contactNumber);
    fd.append("image", image);
    fd.append("url", url);

    console.log(
      guidelines.split(";").map((v, i) => {
        return { type: v };
      })
    );

    axios
      .post(ur, fd, auth)
      .then((res) => {
        console.log(res);
        toast("Hackathon Added Sucessfully");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getHackathon();
  }, []);

  return (
    <>
      <div className="pb-4 sticky w-full flex justify-between items-center  top-0 bg-slate-900">
        <span className="tracking-widest text-blue-400 font-semibold uppercase text-lg">
          Hackathons
        </span>
        <button
          onClick={() => {
            setPopup(!popup);
          }}
          className="md:py-2 px-3 md:px-4 py-1 rounded-sm bg-blue-400 text-white tracking-wider"
        >
          Add Hackathon
        </button>
      </div>

      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          popup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen "
            : "hidden"
        }
      >
        <div
          style={{ maxHeight: "90vh", overflow: "auto" }}
          className="bg-white p-3 px-5 lg:w-3/6  md:w-4/6 w-5/6 mx-auto  rounded-lg"
        >
          <div className="flex justify-between">
            <span className=" font-semibold text-indigo-500 ">
              Add Hackathon
            </span>
            <div className="text-indigo-600 py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
              <IoMdClose
                onClick={() => {
                  setPopup(false);
                }}
              />{" "}
            </div>
          </div>
          {/* form */}

          <form
            className="grid  grid-cols-1 gap-x-7 gap-y-4 py-4"
            onSubmit={(e) => {
              addHackathon(e);
            }}
          >
            {/* Question */}
            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Name
              </label>
              <input
                id="name"
                required
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Organizer
              </label>
              <input
                id="name"
                required
                value={organizer}
                onChange={(e) => {
                  setOrganizer(e.target.value);
                }}
                type="text"
                name="description"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Location
              </label>
              <input
                id="name"
                required
                value={location}
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Tags (This is a list for each new point add a ; (Semi-Colon))
              </label>
              <input
                id="name"
                required
                value={tags}
                onChange={(e) => {
                  setTags(e.target.value);
                }}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Description
              </label>
              <input
                id="name"
                required
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                type="text"
                name="description"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Guidelines (This is a list for each new point add a ;
                (Semi-Colon))
              </label>
              <input
                id="name"
                required
                value={guidelines}
                onChange={(e) => {
                  setGuildelines(e.target.value);
                }}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Rules (This is a list for each new point add a ; (Semi-Colon))
              </label>
              <input
                id="name"
                required
                value={rules}
                onChange={(e) => {
                  setRules(e.target.value);
                }}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Registration Deadline
              </label>
              <input
                id="name"
                required
                value={registrationDeadline}
                onChange={(e) => {
                  setRegistrationDeadline(e.target.value);
                }}
                type="date"
                name="description"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Prize Amount
              </label>
              <input
                id="name"
                required
                value={prizeAmount}
                onChange={(e) => {
                  setPrizeAmount(e.target.value);
                }}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Contact Name
              </label>
              <input
                id="name"
                required
                value={contactName}
                onChange={(e) => {
                  setContactName(e.target.value);
                }}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Contact Number
              </label>
              <input
                id="name"
                required
                value={contactNumber}
                onChange={(e) => {
                  setContactNumber(e.target.value);
                }}
                type="text"
                name="description"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Url
              </label>
              <input
                id="name"
                required
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Image
              </label>
              <input
                accept="image/*"
                id="name"
                onChange={(e) => setImage(e.target.files[0])}
                required
                type="file"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <button
              type="submit"
              className="bg-indigo-500 cursor-pointer w-40 hover:bg-indigo-600 py-1 rounded-full"
            >
              Add
            </button>
          </form>
        </div>
      </section>

      {/* Semesters Data */}
      <div className=" wcomp overflow-y-auto">
        <table className="table-auto  w-full text-left whitespace-no-wrap">
          <thead>
            <tr className="bg-blue-400 border-b border-gray-700 shadow-xl text-white">
              <th className="px-4 py-3 title-font tracking-widest font-medium md:text-base text-sm  ">
                Image
              </th>
              <th className="px-4 py-3 title-font tracking-widest font-medium md:text-base text-sm  ">
                Name
              </th>
              <th className="px-4 py-3 title-font tracking-widest font-medium md:text-base text-sm  ">
                Organizer
              </th>
              <th className="px-4 py-3 title-font tracking-widest rounded-tr-lg font-medium md:text-base text-sm  ">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <Oval color="white" />
            ) : (
              hackathon?.map((data) => {
                return (
                  <tr className="tracking-wider bg-[rgb(30,39,66)] ">
                    <td className="px-4 py-2 w-36 md:w-40">
                      <img
                        src={data.image}
                        alt=""
                        className="w-14 md:w-14 h-14 md:h-14 object-cover "
                      />
                    </td>

                    <td className="px-4 py-3">
                      <a target="_blank" href={data.url}>
                        {data.name}
                      </a>
                    </td>

                    <td className="px-4 py-3">{data.organizer}</td>
                    <td>
                      <FaTrash
                        onClick={() => hackathonDelete(data._id)}
                        className="text-red-600 text-lg md:text-xl cursor-pointer"
                      />
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default HOC(Hackathon);
