import axios from "axios";
import React, { useEffect, useState } from "react";
import HOC from "../../layout/HOC";
import { IoMdClose } from "react-icons/io";
import { Oval } from "react-loader-spinner";
import Baseurl from "../../../Baseurl";
import { FaTrash, FaEdit } from "react-icons/fa";
import auth from "../../../Auth";
import { statesList } from "../../../States";

const University = () => {
  const [courses, setCourses] = useState([]);
  const [courseName, setCourseName] = useState("");
  const [popup, setPopup] = useState(false);
  const [id, setId] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedState, setSelectedState] = useState();

  const handleStateDropdownChange = (e) => {
    setSelectedState(e.target.value);
  };

  const getCourses = async () => {
    const url = Baseurl() + "university/getallUniversity";
    try {
      const res = await axios.get(url, auth);
      console.log("res", res);
      setCourses(res.data.allcourses);
      setLoading(false);
    } catch (err) {
      console.log("err", err);
    }
  };

  const addCourse = async (e) => {
    e.preventDefault();
    const url = Baseurl() + "university/addUniversity";

    const data = {
      name: courseName,
      state: selectedState
    };

    try {
      const res = await axios.post(url, data, auth);
      console.log("res", res);
      alert("Added Successfully");
      getCourses();
    } catch (err) {
      console.log("err", err);
      alert("Please try again");
    }
  };

  const deleteCourse = async (id) => {
    if (!window.confirm("Are you sure")) {
      return;
    }
    const url = Baseurl() + `university/deleteUniversity/${id}`;
    try {
      const res = await axios.delete(url, auth);
      console.log("res", res);
      alert("Deleted Successfully");
      getCourses();
    } catch (err) {
      console.log("err", err);
      alert("Please try again");
    }
  };

  const handleClick = (data) => {
    setCourseName(data?.coursename);
    setId(data?._id);
  };

  const editCourse = async (e) => {
    e.preventDefault();
    const url = Baseurl() + `university/updateUniversity/${id}`;

    const data = {
      name: courseName,
      state: selectedState
    };
    console.log(data);
    try {
      const res = await axios.put(url, data, auth);
      console.log("res", res);
      alert("Successful");
      getCourses();
    } catch (err) {
      console.log("err", err);
      alert("Please try again");
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <>
      <div className="pb-4 sticky w-full flex justify-between items-center  top-0 bg-slate-900">
        <span className="tracking-widest text-blue-400 font-semibold uppercase text-lg">
          All Universities
        </span>
        <button
          onClick={() => {
            setPopup(!popup);
          }}
          className="md:py-2 px-3 md:px-4 py-1 rounded-sm bg-blue-400 text-white tracking-wider"
        >
          Add University
        </button>
      </div>
      {/* Add Courses Form*/}
      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          popup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen "
            : "hidden"
        }
      >
        <div
          style={{ maxHeight: "90vh" }}
          className="bg-white p-3 px-5 lg:w-3/6  md:w-4/6 w-5/6 mx-auto  rounded-lg"
        >
          <div className="flex justify-between">
            <span className=" font-semibold text-indigo-500 ">
              {id ? "Edit University" : "Add University"}
            </span>
            <div className="text-indigo-600 py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
              <IoMdClose
                onClick={() => {
                  setPopup(false);
                  setCourseName("");
                }}
              />{" "}
            </div>
          </div>
          {/* form */}

          <form
            className="grid  grid-cols-1 gap-x-7 gap-y-4 py-4"
            onSubmit={id ? editCourse : addCourse}
          >
            {/* Question */}
            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                University Name
              </label>
              <input
                id="name"
                required
                value={courseName}
                onChange={(e) => setCourseName(e.target.value)}
                type="text"
                name="coursename"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                State
              </label>
              <select
                className="md:py-2 px-3 md:px-4 py-1 rounded-sm  text-black tracking-wider mt-1 w-[300px]"
                value={selectedState}
                onChange={handleStateDropdownChange}
                as="select"
                id="state"
                type="text"
                name="state"
              >
                <option>--Select--</option>
                {statesList.map((u) => {
                  return <option value={u}>{u}</option>;
                })}
            </select>
            </div>

            <button
              type="submit"
              className="bg-indigo-500 cursor-pointer w-40 hover:bg-indigo-600 py-1 rounded-full"
            >
              Add
            </button>
          </form>
        </div>
      </section>
      {/* Courses Data */}
      <div className=" wcomp overflow-y-auto">
        <table className="table-auto  w-full text-left whitespace-no-wrap">
          <thead>
            <tr className="bg-blue-400 border-b border-gray-700 shadow-xl text-white">
              <th className="px-4 py-3  tracking-widest font-medium md:text-base text-sm rounded-tl-lg ">
                University Name
              </th>
              <th
                colSpan={8}
                className="px-4 py-3  tracking-widest font-medium md:text-base text-sm rounded-tl-lg "
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <Oval color="white" />
            ) : (
              courses &&
              courses?.map((data) => {
                return (
                  <tr className="tracking-wider bg-[rgb(30,39,66)] ">
                    <td className="px-2 py-3 md:text-base text-sm ">
                      {data.name + ` (${data.state})`} 
                    </td>

                    <td
                      colSpan={8}
                      className="px-4 py-3 flex flex-row justify-between space-x-2"
                    >
                      <FaEdit
                        onClick={() => {
                          setPopup(!popup);
                          handleClick(data);
                        }}
                        className="text-red-600 text-lg md:text-xl cursor-pointer"
                      />
                      <FaTrash
                        onClick={() => deleteCourse(data._id)}
                        className="text-red-600 text-lg md:text-xl cursor-pointer"
                      />
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default HOC(University);
