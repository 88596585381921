export const statesList = [
    "Andhra Pradesh",
    "Telangana",
    "Punjab",
    "Gujarat",
    "Tamil Nadu",
    "West Bengal",
    "Kerala",
    "Uttar Pradesh",
    "Karnataka",
    "Bihar",
    "Assam",
    "Madhya Pradesh",
    "Chhatisgarh",
    "Himachal Pradesh",
    "Odisha",
    "Haryana",
    "Maharashtra",
    "Delhi",
    "Jharkhand",
    "Rajasthan",
    "Lakshadweep",
    "Sikkim",
    "Manipur",
    "Tripura",
    "Meghalaya",
    "Arunachal Pradesh",
    "Mizoram",
    "Uttrakhand",
    "Goa",
    "Dadra & Nagar Haveli",
    "Daman & Diu",
    "Chandigarh",
    "Nagaland",
    "Jammu and Kashmir",
    "Puducherry",
    "Andaman & Nicobar Islands"
];