import axios from "axios";
import React, { useEffect, useState } from "react";
import HOC from "../../layout/HOC";
import Baseurl from "../../../Baseurl";
import { CgProfile } from "react-icons/cg";
import auth from "../../../Auth";
import { IoMdClose } from "react-icons/io";
import { GrNotification } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";
import { data } from "autoprefixer";

const MockInterviews = () => {
  const [mockinterviews, setMockinterviews] = useState([]);
  const [popup, setPopup] = useState(false);
  const [title1, setTitle1] = useState();
  const [price1, setPrice1] = useState();
  const [title2, setTitle2] = useState();
  const [price2, setPrice2] = useState();
  const [title3, setTitle3] = useState();
  const [price3, setPrice3] = useState();
  const [packageData, setPackageData] = useState();
  const [loading, setLoading] = useState(true);

  const getMockinterviews = async () => {
    const url = Baseurl() + "mockinterview/getAllPackageSubscription";
    try {
      axios
        .get(url, auth)
        .then((res) => {
          setMockinterviews(res.data.packageSubscriptions);
          setLoading(false);
        })
        .catch((res) => {
          setLoading(false);
        });
    } catch (err) {
      console.log("err", err);
    }
  };

  const addPackage = async (e, o) => {
    e.preventDefault();
    const url = Baseurl() + "mockinterview/addPackageData";

    const data = {
      data: o,
    };

    try {
      const res = await axios.post(url, data, auth);
      getPackageData();
      console.log("res", res);
      alert("Added Successfully");
      setPopup(false);
    } catch (err) {
      console.log("err", err);
      alert("Please try again");
    }
  };

  const getPackageData = async () => {
    const url = Baseurl() + "mockinterview/getPackageData";
    try {
      axios
        .get(url, auth)
        .then((res) => {
          setPackageData(res.data.latestPackageData[0].data ?? "");
          setLoading(false);
        })
        .catch((res) => {
          setLoading(false);
        });
    } catch (err) {
      console.log("err", err);
    }
  };

  const completePackage = async (id) => {
    if (!window.confirm("You are marking this interview as complete")) {
      return;
    }

    const url = Baseurl() + "mockinterview/updatePackageSubscriptionStatus";

    const data = {
      _id: id,
      status: "Completed",
    };

    try {
      const res = await axios.put(url, data, auth);
      console.log("res", res);
      getMockinterviews();
      alert("Completed Successfully");
    } catch (err) {
      console.log("err", err);
      alert("Please try again");
    }
  };

  useEffect(() => {
    getMockinterviews();
    getPackageData();
  }, []);

  return (
    <>
      <div className="pb-4 sticky w-full flex justify-between items-center  top-0 bg-slate-900">
        <span className="tracking-widest text-blue-400 font-semibold uppercase text-lg">
          Mock Interviews Requests
        </span>
        <button
          onClick={() => {
            setPopup(!popup);
          }}
          className="md:py-2 px-3 md:px-4 py-1 rounded-sm bg-blue-400 text-white tracking-wider"
        >
          Add/ Update Packages
        </button>
      </div>
      <div className="pb-4 sticky w-full flex start items-center  top-0 bg-slate-900">
        <span className="tracking-widest text-blue-400 font-semibold uppercase text-lg">
          Cost
        </span>
        &nbsp;
        &nbsp;
        &nbsp;  
        {packageData !== undefined && 
        <div className="grid  grid-cols-1 gap-x-7 gap-y-4 py-4">
          <span className=" font-semibold text-white-500 ">Package 1 : &nbsp; &nbsp;{JSON.parse(packageData).data[0]?.title ?? "No Title"} &nbsp; &nbsp; {JSON.parse(packageData).data[0]?.price ?? "No Price"}</span>
          <span className=" font-semibold text-white-500 ">Package 1 : &nbsp; &nbsp;{JSON.parse(packageData).data[1]?.title ?? "No Title"} &nbsp; &nbsp; {JSON.parse(packageData).data[1]?.price ?? "No Price"}</span>
          <span className=" font-semibold text-white-500 ">Package 1 : &nbsp; &nbsp;{JSON.parse(packageData).data[2]?.title ?? "No Title"} &nbsp; &nbsp; {JSON.parse(packageData).data[2]?.price ?? "No Price"}</span>
        </div>}
      </div>
      <section
        style={{ background: "rgba(0,0,0,0.4)" }}
        className={
          popup
            ? "fixed top-0 left-0 wcomp transition-all duration-150 w-full flex justify-center items-center h-screen "
            : "hidden"
        }
      >
        <div
          style={{ maxHeight: "90vh" }}
          className="bg-white p-3 px-5 lg:w-3/6  md:w-4/6 w-5/6 mx-auto  rounded-lg"
        >
          <div className="flex justify-between">
            <span className=" font-semibold text-indigo-500 ">
              Set Package Price
            </span>
            <div className="text-indigo-600 py-0.5 text-2xl cursor-pointer font-medium tracking-wider">
              <IoMdClose
                onClick={() => {
                  setPopup(false);
                }}
              />{" "}
            </div>
          </div>
          {/* form */}
          <form
            className="grid  grid-cols-1 gap-x-7 gap-y-4 py-4"
            onSubmit={(e) => {
              let object = { data : [
                {
                  title: title1,
                  price: price1,
                },
                {
                  title: title2,
                  price: price2,
                },
                {
                  title: title3,
                  price: price3,
                },
              ]
            };
              setPackageData(JSON.stringify(object));
              addPackage(e, JSON.stringify(object));
            }}
          >
            {/* Question */}
            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Package 1 Title
              </label>
              <input
                id="name"
                required
                value={title1}
                onChange={(e) => {
                  setTitle1(e.target.value);
                }}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Package 1 Price
              </label>
              <input
                id="name"
                required
                value={price1}
                onChange={(e) => {
                  setPrice1(e.target.value);
                }}
                type="text"
                name="description"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Package 2 Title
              </label>
              <input
                id="name"
                required
                value={title2}
                onChange={(e) => {
                  setTitle2(e.target.value);
                }}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Package 2 Price
              </label>
              <input
                id="name"
                required
                value={price2}
                onChange={(e) => {
                  setPrice2(e.target.value);
                }}
                type="text"
                name="description"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Package 3 Title
              </label>
              <input
                id="name"
                required
                value={title3}
                onChange={(e) => {
                  setTitle3(e.target.value);
                }}
                type="text"
                name="categoryname"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <div className="inline-flex  w-full flex-col">
              <label
                htmlFor="name"
                className="text-gray-800 mb-1.5 tracking-wider font-semibold text-sm"
              >
                Package 3 Price
              </label>
              <input
                id="name"
                required
                value={price3}
                onChange={(e) => {
                  setPrice3(e.target.value);
                }}
                type="text"
                name="description"
                placeholder=""
                className="bg-gray-100 text-gray-800 tracking-wider text-sm rounded-full py-1 px-2 outline-indigo-400"
              />
            </div>

            <button
              type="submit"
              className="bg-indigo-500 cursor-pointer w-40 hover:bg-indigo-600 py-1 rounded-full"
            >
              Add
            </button>
          </form>
        </div>
      </section>

      {/* Semesters Data */}
      <div className=" wcomp overflow-y-auto">
        <table className="table-auto  w-full text-left whitespace-no-wrap">
          <thead>
            <tr className="bg-blue-400 border-b border-gray-700 shadow-xl text-white">
              <th className="px-4 py-3 title-font tracking-widest font-medium md:text-base text-sm  ">
                Mentor Category
              </th>
              <th className="px-4 py-3 title-font tracking-widest font-medium md:text-base text-sm  ">
                Whatsapp Number
              </th>
              <th className="px-4 py-3 title-font tracking-widest font-medium md:text-base text-sm  ">
                Package Type
              </th>
              <th className="px-4 py-3 title-font tracking-widest font-medium md:text-base text-sm  ">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <Oval color="white" />
            ) : (
              mockinterviews.reverse()?.map((data) => {
                return (
                  <tr className="tracking-wider bg-[rgb(30,39,66)] ">
                    <td className="px-4 py-3">{data.mentorCategory}</td>

                    <td className="px-4 py-3">{data.whatsappNumber}</td>

                    <td className="px-4 py-3">{data.packageType}</td>

                    {data.status === "Completed" ? (
                      <td className="px-4 py-3">{data.status}</td>
                    ) : (
                      <FaCheck
                        onClick={() => completePackage(data._id)}
                        className="text-red-600 text-lg md:text-xl cursor-pointer"
                      />
                    )}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default HOC(MockInterviews);
